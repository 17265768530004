<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page_header_new";
import appConfig from "@/app.config";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";
import {AgGridVue} from "ag-grid-vue";

/**
 * Starter-page component
 */
export default {
  page: {
    title: "알림톡 발송내역",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "발송 내역",
      items: '알림톡 전송 내역을 확인할 수 있습니다.',
      gridApi: null,
      columnApi: null,
      columnDefs: [
        {headerName: '발송일시', field: 'reg_date'},
        {headerName: '발송요청건수', field: 'total'},
        {headerName: '성공', field: 'success'},
        {headerName: '실패', field: 'fail'}
      ],
      historyList: [],
    };
  },
  components: {
    Layout,
    PageHeader,
    AgGridVue,
  },
  computed: {
    ...mapState(loggedInfoObject),
  },
  mounted() {
    this.getHistory()
  },
  methods: {
    //ag-grid methods
    onFirstDataRendered(event) {
      event.api.sizeColumnsToFit()
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.$refs.default_input.value)
    },
    onGridReady(params) {
      this.gridApi = params.api
    },

    getHistory() {
      let url = `/api/kakao/history/sendtalk/${this.logged_info.mart_srl}`

      axios.get(url).then((res) => {
        //console.log(res.data)

        this.historyList = res.data.reverse()
      }).catch(err => console.log(err))
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="card">
      <div class="card-body">
        <ag-grid-vue
            style="height: calc(100vh - 340px); width: 100%; text-align: center;"
            class="ag-theme-alpine"
            :columnDefs="columnDefs"
            :rowData="historyList"
            rowSelection="multiple"
            @grid-ready="onGridReady"
            @first-data-rendered="onFirstDataRendered"
            :cacheQuickFilter="true"
            suppressHorizontalScroll="false"
            enableCellTextSelection="true"
        ></ag-grid-vue>
      </div>
    </div>
  </Layout>
</template>

<style>
.ag-header-cell-label {
  justify-content: center;
}

.ag-cell {
  border-right: 1px solid !important;
  border-top: 0 !important;
  border-color: #f4f4f4 !important;
}
</style>
