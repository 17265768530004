import { render, staticRenderFns } from "./talkHistory.vue?vue&type=template&id=2d787962"
import script from "./talkHistory.vue?vue&type=script&lang=js"
export * from "./talkHistory.vue?vue&type=script&lang=js"
import style0 from "./talkHistory.vue?vue&type=style&index=0&id=2d787962&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports